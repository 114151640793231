import UserRole from './UserRole';
import PlayerView from '/js/components/html_components/PlayerView';

/*
Role: cust_bodyworn_admin

*/
export default class CustBWAdminRole extends UserRole
{
  constructor(name)
  {
    super(name);
  }

  configure_app()
  {
    PlayerView.show_remote_buttons(); 
  }
}
